import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/styles";

import {
  DialogTitle,
  Dialog,
  DialogContent,
  Typography
} from "@material-ui/core";

const styles = makeStyles(theme => ({
  thankYouContainer: {
    width: "35%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  thankYouContent: {
    textAlign: "center",
    padding: "40px 40px 0px 40px !important",
    [theme.breakpoints.down("md")]: {
      padding: "30px 30px 0px 30px !important",
      fontSize: 14
    }
  },
  thankYouText: {
    textAlign: "center",
    fontWeight: "bold",
    padding: "30px",
    [theme.breakpoints.down("md")]: {
      padding: 10,
      fontSize: "2rem"
    }
  }
}));

const ThankYouModal = () => {
  const classes = styles();

  const [openModal, setOpenModal] = useState(true);

  return (
    <Dialog
      onClose={() => setOpenModal(false)}
      open={openModal}
      className={classes.thankYouContainer}
    >
      <DialogContent className={classes.thankYouContent}>
        {/* It was humbling to see the phenomenal response to the #LetsFeedTogether
        initiative when India went under lockdown and the underpriviledged
        required food support. As the country is embracing selective relaxation
        of the lockdown, the migrant and daily wage earners are being provided
        transport facilities to return home. At this juncture, after having
        served over 3 million + meals, we are in the process of fulfilling all
        current commitments in hand; however, we are not currently taking
        contributions beyond the May 4, 2020.
        <br />
        <br />
        We want to thank everyone who was a part of this cause, it would not
        have been possible without you! */}
        It was humbling to see the phenomenal response to the #LetsFeedTogether
        initiative when India went under lockdown and the underprivileged
        required food support. With partial relaxation of lockdown, after having
        served over 3 million+ meals, we are currently assessing the situation
        and are not taking further orders for the time being. Our heartfelt
        thanks to all our partners, customers and employees to have made this
        initiative possible.
      </DialogContent>
      <DialogTitle id="simple-dialog-title">
        <Typography variant="h3" className={classes.thankYouText}>
          THANK YOU
        </Typography>
      </DialogTitle>
    </Dialog>
  );
};

export default ThankYouModal;
