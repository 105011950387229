import React, { useEffect, useState } from "react";
import classnames from "classnames";

import { connect } from "react-redux";

import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Navbar from "../components/Home/NavBar";
import MealCount from "../components/Home/MealCount";
import Contribution from "../components/Home/Contribution";
// import RecentActivity from "../components/Home/RecentActivity";
// import WhyHowWhat from "../components/Home/WhyHowWhat";
import JoinUs from "../components/Home/JoinUs";
import ImageShowcase from "../components/Home/ImageShowcase";
import MealsTracker from "../components/Home/MealsTracker";
import LFTJourney from "../components/Home/LFTJourney";

// import FeedingImage from "../assets/homeMainImage.png";
// import FeedingImage from "../assets/HeaderImageHomepage.jpg";
import FeedingImage from "../assets/bannerImage.jpg";
import { GET_STATS_URL } from "../utils/Urls";

const styles = makeStyles(theme => ({
  root: {
    overflowX: "hidden"
  },
  main: {
    width: "65%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  feedingImageContainer: {
    backgroundColor: theme.commonStyles.greyShade,
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px"
    }
  },
  feedingImage: {
    width: "100%",
    height: "auto"
  },
  starterContent: {
    width: "50%",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "5px"
    }
  },
  knowMoreBtn: {
    width: "200px",
    fontSize: "20px",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      width: "125px",
      fontSize: "14px",
      margin: "0px 15px 5px 0px",
      padding: "4px 12px"
    }
  },
  letsFeedTogether: {
    color: theme.commonStyles.themeColor,
    paddingBottom: "20px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      paddingBottom: "3px"
    }
  },
  flexDisplay: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center"
    }
  },
  starterImageFirstArea: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  }
}));

const StartImage = props => {
  const classes = styles();

  const knowMoreHandler = () => {
    props.history.push("/know-more");
  };

  return (
    <>
      <div className={classes.feedingImageContainer}>
        <div className={classnames(classes.main, classes.flexDisplay)}>
          <div className={classes.starterImageFirstArea}>
            <img
              src={FeedingImage}
              alt="offering-food"
              className={classes.feedingImage}
            />
          </div>
          <div className={classes.starterContent}>
            <Typography variant="h4" className={classes.letsFeedTogether}>
              #LetsFeedTogether
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.knowMoreBtn}
              onClick={knowMoreHandler}
            >
              Know More
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const Home = props => {
  const classes = styles();
  const [stats, setStats] = useState({});

  useEffect(() => {
    fetch(GET_STATS_URL)
      .then(res => {
        return res.json();
      })
      .then(response => {
        // console.log("response", response);
        if (response) {
          setStats(response.extras || {});
        }
      })
      .catch(err => {
        console.log("err", err);
      });
  }, []);

  return (
    <div className={classes.root}>
      <Navbar />
      <StartImage data={stats} history={props.history} />
      <div className={classes.main}>
        <MealCount data={stats} />
        {props.enableOrdering ? <Contribution /> : <LFTJourney />}
        <ImageShowcase data={stats} />
        <MealsTracker />
        {/* <RecentActivity data={stats} /> */}

        {/* <JoinUs /> */}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    enableOrdering: state.firebaseStaticData.enableOrdering || false
  };
};

export default connect(mapStateToProps, null)(Home);
