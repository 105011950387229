import React from "react";
import classnames from "classnames";

import { makeStyles } from "@material-ui/styles";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import mainImg from "../../assets/contributionMainImage.png";
import { PAGE_WIDTH } from "../../utils/Constants";

const styles = makeStyles((theme) => ({
  root: {
    width: "65%",
    margin: "0px auto 70px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "60px",
    },
  },
  container: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 15px",
    },
  },
  mainImg: {
    padding: "0 16px 16px",
    width: PAGE_WIDTH,
    height: PAGE_WIDTH / 3,
    maxWidth: PAGE_WIDTH,
    maxHeight: PAGE_WIDTH / 3,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxHeight: window.innerWidth / 3,
    },
  },
  title: {
    fontWeight: "bold",
    padding: "20px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 0px 5px",
      fontSize: "26px",
    },
  },
  content: {
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: "1.6",
    opacity: "0.7",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  themeColor: {
    color: theme.commonStyles.themeColor,
  },
  foodImageContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: "center",
    width: "75%",
    margin: "auto",
    paddingTop: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  mealServedImage: {
    height: "200px",
    width: "200px",
    [theme.breakpoints.down("sm")]: {
      height: "100px",
      width: "100px",
    },
  },
  paddingLeft: {
    paddingLeft: "15px",
  },
}));

const WhyHowWhat = (props) => {
  const classes = styles();

  return (
    <div className={classes.root}>
      <div className={classes.container} id="info">
        <Grid container justify="center">
          <img src={mainImg} className={classes.mainImg} alt="Contribution" />
        </Grid>
        <Typography
          variant="h4"
          className={classnames(classes.title, classes.themeColor)}
        >
          Why
        </Typography>
        <Typography variant="h6" className={classes.content}>
          #LetsFeedTogether is a platform created by Compass Group India, to
          help in the fight for food security. Compass Group is the world’s
          leading Contract Food Services Provider. This platform brings together
          three key stakeholders, 1. The Food Orderer 2. Large Kitchens and 3.
          Distribution Partners. We think, if these three stakeholders get
          together, we can get freshly cooked meals to those who need it the
          most. We think, this partnership can do it with pace and do it at
          scale. You order, we or our partner kitchens cook, and distribution
          partners like Government Agencies and NGOs distribute the food.
          Simple!
        </Typography>

        <Typography
          variant="h4"
          className={classnames(classes.title, classes.themeColor)}
        >
          What
        </Typography>
        <Typography variant="h6" className={classes.content}>
          We are preparing fresh, wholesome single pot dishes such as Achari
          Daal Khichdi with Vegetables, Vegetable Sambhar Rice, Tomato Khichdi
          with Vegetables etc. Each individual meal would be approximately 400
          to 450 grams by weight. The price of each meal is Rs.30/- (Including
          Taxes), just enough to cover our costs.
        </Typography>
        <Typography
          variant="h4"
          className={classnames(classes.title, classes.themeColor)}
        >
          How
        </Typography>
        <Typography variant="h6" className={classes.content}>
          Recipes have been tweaked to improve shelf life. Food is cooked in
          batches, i.e separately for Lunch and Dinner, at either our Compass
          Group India Kitchens or at our Partner Kitchens. The food is hot
          packed in suitable material to keep it as fresh as possible. Packed
          meals are then delivered free of cost to the needy through
          distribution partners. You will get an update about where the meals
          bought by you were distributed. The cities where we have kitchen
          operations are Delhi NCR, Bangalore, Mumbai, Hyderabad and Chennai.
          Our efforts will currently be focussed on bringing relief in these
          cities.
        </Typography>
      </div>
    </div>
  );
};

export default WhyHowWhat;
