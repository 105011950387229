import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

// import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Appbar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import { List, ListItem, ListItemText } from "@material-ui/core";
// import ClickAwayListener from "@material-ui/core/ClickAwayListener";
// import Slide from "@material-ui/core/Slide";
// import IconButton from "@material-ui/core/IconButton";

import MenuIcon from "@material-ui/icons/Menu";

import CompassImage from "../../assets/compass-group-logo.jpg";

const DrawerList = [
  { key: "know-more", label: "Know More", route: "/know-more" },
  { key: "meals-served", label: "Meals Served", route: "/meals-served" },
  // { key: "join-us", label: "Join Us", route: "/join-us" },
  { key: "faqs", label: "FAQs", route: "/faqs" },
  // { key: "contact-us", label: "Contact Us", route: "/contact-us" }
];

const navItems = [
  { id: "home", label: "Home", route: "/" },
  // { id: "fund-usage", label: "Fund Usage" },
  { id: "meals-served", label: "Meals Served", route: "/meals-served" },
  { id: "know-more", label: "Know More", route: "/know-more" },
  { id: "faq", label: "FAQ", route: "/faqs" },
  // { id: "contact-us", label: "Contact Us", route: "/contact-us" }
  // { id: "share", label: "Share", route: "/share" }
];

const DRAWER_WIDTH = "240px";

const styles = makeStyles(theme => ({
  main: {
    width: "70%",
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  navItemsContainer: {
    width: "75%",
    display: "flex",
    // justifyContent: "space-around", // use this later
    justifyContent: "flex-end",
    alignItems: "center"
  },
  mobileAppbarRoot: {
    backgroundColor: "white",
    height: "60px",
    zIndex: theme.zIndex.drawer + 1
  },
  toolbar: {
    width: "30%",
    paddingLeft: "8px"
  },
  menuButton: {
    marginRight: "10px"
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: "0",
    zIndex: "10000"
  },
  drawerPaper: {
    width: DRAWER_WIDTH
  },
  navItemsDesktop: {
    opacity: "0.5",
    fontWeight: "bold",
    paddingRight: "25px",
    "&:hover": {
      cursor: "pointer"
    }
  }
}));

// const HideOnScroll = props => {
//   const { children, window } = props;

//   return (
//     <Slide appear={false} direction="down">
//       {children}
//     </Slide>
//   );
// };

const Navbar = props => {
  const classes = styles();
  // const shareEl = useRef(null);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const navigteTo = pageId => () => {
    setIsDrawerOpen(false);
    if (pageId === "/join-us") {
      props.history.push({
        pathname: "/"
      });
      setTimeout(() => {
        document.getElementById("joinUs").scrollIntoView();
      }, 1000);
    } else {
      props.history.push({
        pathname: pageId
      });
    }
  };

  return (
    <>
      <Hidden smDown>
        <div className={classes.main}>
          <div style={{ width: "25%" }}>
            <img
              src={CompassImage}
              alt="compass-group"
              style={{
                height: "60px",
                width: "auto"
              }}
            />
          </div>
          <div className={classes.navItemsContainer}>
            {navItems.map(item => (
              <Typography
                variant="h6"
                key={item.id}
                onClick={navigteTo(item.route)}
                className={classes.navItemsDesktop}
              >
                {item.label}
              </Typography>
            ))}
          </div>
        </div>
      </Hidden>
      <Hidden mdUp>
        {/* <div className={classes.main}>
          <div style={{ width: "35%" }}>
            <img
              src={CompassImage}
              alt="compass-group"
              style={{ width: "100%" }}
              // height="40px"
              // width="111px"
            />
          </div>
          <div
            style={{ width: "65%", textAlign: "right", paddingRight: "25px" }}
          >
          <Typography
          variant="subtitle2"
          style={{ fontSize: "16px", fontWeight: "bold", opacity: "0.5" }}
          ref={shareEl}
          >
          Share
          </Typography>
          </div>
        </div> */}
        {/* <HideOnScroll> */}
        <Appbar className={classes.mobileAppbarRoot}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Toolbar className={classes.toolbar}>
              {/* <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            > */}
              <MenuIcon
                className={classes.menuButton}
                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
              />
              {/* </IconButton> */}
              <img
                src={CompassImage}
                alt="compass-log"
                style={{ width: "100%" }}
              />
            </Toolbar>
            <div
              style={{
                width: "65%",
                textAlign: "right",
                paddingRight: "25px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center"
              }}
            >
              <Typography
                variant="subtitle2"
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  opacity: "0.5",
                  paddingRight: "10px"
                }}
                onClick={navigteTo("/")}
              >
                Home
              </Typography>
              {/* <Typography
                variant="subtitle2"
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  opacity: "0.5"
                }}
              >
                Share
              </Typography> */}
            </div>
          </div>
        </Appbar>
        {isDrawerOpen && (
          <div
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "rgba(0,0,0,0.3)",
              position: "fixed",
              top: 0,
              zIndex: 100
            }}
            onClick={() => setIsDrawerOpen(false)}
          ></div>
        )}
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <List style={{ paddingTop: "60px" }}>
            {DrawerList.map((item, index) => (
              <ListItem button key={item.key} onClick={navigteTo(item.route)}>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
          </List>
        </Drawer>
        {/* </ClickAwayListener> */}
        {/* </HideOnScroll> */}
      </Hidden>
    </>
  );
};

export default withRouter(Navbar);
