import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/styles";

import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";

import ArrowLeft from "@material-ui/icons/KeyboardArrowLeft";

import MealServedListMobile from "./MealServedListMobile";
import MealServedListDesktop from "./MealServedListDesktop";

import { COLORS } from "../../utils/Theme";

// import { sampleData } from "../utils/data";

const styles = makeStyles(theme => ({
  topBar: {
    width: "65%",
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  backBtn: {
    padding: "10px",
    background: "white",
    fontWeight: "bold"
  },
  hashtag: {
    color: COLORS.PRIMARY_COLOR,
    padding: "8px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  }
}));

const MealsServedList = props => {
  const { firebaseStaticData = {} } = props;
  const { mealsServed: sampleData = [] } = firebaseStaticData;
  const classes = styles();

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  return (
    <>
      <div className={classes.topBar}>
        <Button
          color="primary"
          className={classes.backBtn}
          startIcon={<ArrowLeft style={{ margin: 0 }} />}
          onClick={() => props.history.goBack()}
        >
          Go Back
        </Button>
        <Typography variant="h6" className={classes.hashtag}>
          #LetsFeedTogether
        </Typography>
      </div>
      <Hidden mdUp>
        <MealServedListMobile details={sampleData} />
      </Hidden>
      <Hidden smDown>
        <MealServedListDesktop details={sampleData} />
      </Hidden>
    </>
  );
};

const mapStateToProps = state => {
  return {
    firebaseStaticData: state.firebaseStaticData
  };
};

export default withRouter(connect(mapStateToProps, null)(MealsServedList));
