import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/styles";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import ArrowRight from "@material-ui/icons/KeyboardArrowRight";

import MobileDisplayCard from "./MealDataDisplayCardMobile";

// import FlashingDotGif from "../../assets/recentServings.gif";

const styles = makeStyles(theme => ({
  mobileRoot: {
    padding: "15px",
    borderRadius: "10px",
    backgroundColor: "#f0f0f0",
    textAlign: "left",
    marginBottom: "10px"
  },
  titleContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "15px 15px 0px"
  },
  sectionTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px"
    }
  },
  moreInfo: {
    textDecoration: "underline",
    color: theme.commonStyles.themeColor,
    "&:hover": {
      cursor: "pointer"
    }
  },
  value: {
    fontSize: "15px",
    fontWeight: "bold",
    opacity: "0.58",
    lineHeight: "1.25"
  },
  title: {
    fontSize: "13px",
    fontWeight: "bold",
    opacity: "0.4",
    lineHeight: "1.25"
  },
  btnContainer: {
    textAlign: "right"
  },
  trackBtn: {
    padding: "0px 15px 15px",
    background: "white",
    fontWeight: "bold"
  }
}));

const MealsTracker = props => {
  const classes = styles();
  const { firebaseStaticData = {} } = props;
  const { mealsServed = [] } = firebaseStaticData;

  let conciseData = [];
  if (mealsServed.length) {
    conciseData = [...mealsServed].slice(0, 3);
  }

  const viewTrackerHandler = () => {
    props.history.push("/meals-served");
  };

  return (
    <div style={{ marginBottom: "75px" }}>
      <div className={classes.titleContainer}>
        {conciseData.length > 0 && (
          <Typography variant="h6" className={classes.sectionTitle}>
            Meals Served Tracker
          </Typography>
        )}
        {/* <img src={FlashingDotGif} alt="flashing-dot-live" /> */}
      </div>
      <div style={{ padding: "15px 15px 0px" }}>
        {conciseData.map((datum, index) => (
          <MobileDisplayCard data={datum} showViewMore key={index} />
        ))}
      </div>
      <div className={classes.btnContainer}>
        <Button
          color="primary"
          className={classes.trackBtn}
          endIcon={<ArrowRight style={{ margin: 0 }} />}
          onClick={viewTrackerHandler}
        >
          view full tracker
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    firebaseStaticData: state.firebaseStaticData
  };
};

export default withRouter(connect(mapStateToProps, null)(MealsTracker));
