import React from "react";

import { makeStyles } from "@material-ui/styles";

import { Carousel } from "react-responsive-carousel";

const styles = makeStyles(theme => ({
  imageStyles: {
    width: "100%",
    borderRadius: "15px"
  }
}));

const DesktopImageShowcase = props => {
  const { images = [] } = props;

  return (
    <div style={{ padding: "15px 0px" }}>
      <Carousel
        emulateTouch
        centerMode
        centerSlidePercentage={props.centerSlidePercentage || "40"}
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
        // autoPlay
        // infiniteLoop
        // transitionTime={1500}
      >
        {images.map(imageSrc => (
          <div style={{ borderRadius: "15px", marginRight: "25px" }}>
            <img
              src={imageSrc}
              alt={imageSrc.split("/").pop()}
              style={{
                width: props.imgWidth || "100%",
                height: props.imgHeight
              }}
              // className={classes.imageStyles}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default DesktopImageShowcase;
