import React from "react";

import { makeStyles } from "@material-ui/styles";

import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";

import { Carousel } from "react-responsive-carousel";

import Testimony from "../../../assets/squooshed7.jpeg";

const styles = makeStyles(theme => ({
  carouselRoot: {
    margin: "20px 0px 40px"
  },
  iframeHeight: {
    height: "400px"
  },
  container: {
    width: "100%",
    overflowX: "auto",
    whiteSpace: "nowrap",
    padding: "15px 0px"
  },
  individualImgContainer: {
    display: "inline-block",
    padding: "0px 15px",
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "80%"
    }
  },
  imageStyles: {
    width: "100%",
    height: "190px"
  },
  titleContainer: {
    // display: "flex",
    // justifyContent: "space-between",
    // alignItems: "center",
    padding: "15px 0px 0px"
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px"
    }
  }
}));

const Title = props => {
  const classes = styles();

  return (
    <Typography variant="h6" className={classes.title}>
      Testimonials
    </Typography>
  );
};

const ImageDisplay = props => {
  const classes = styles();
  const { details } = props;

  return (
    <div className={classes.individualImgContainer}>
      <img
        src={details}
        alt={details.split("/").pop()}
        className={classes.imageStyles}
      />
    </div>
  );
};

const LFTTestimonials = props => {
  const classes = styles();

  return (
    <React.Fragment>
      <div className={classes.titleContainer}>
        <Title />
        <Hidden smDown>
          <Carousel
            className={classes.carouselRoot}
            // showIndicators={false}
            showStatus={false}
            showThumbs={false}
            showArrows={true}
          >
            <div>
              <iframe
                title="youtube-video-one"
                src="https://www.youtube.com/embed/KOJja-uOYwU"
                height="400"
              />
            </div>
            <div style={{ height: "400px" }}>
              <img
                src={Testimony}
                alt="testimonial"
                style={{ height: "400px", width: "400px" }}
              />
            </div>
            <div>
              <iframe
                title="youtube-video-one"
                src="https://www.youtube.com/embed/JjzMDpNgKbs"
                height="400"
              />
            </div>
          </Carousel>
        </Hidden>
        <Hidden mdUp>
          <div style={{ padding: "0px 12px" }}>
            <div className={classes.container}>
              <iframe
                title="youtube-video-one"
                src="https://www.youtube.com/embed/KOJja-uOYwU"
                height="185"
                width="90%"
                allowFullScreen
              />
              <ImageDisplay details={Testimony} key="testimony" />
              <iframe
                title="youtube-video-one"
                src="https://www.youtube.com/embed/JjzMDpNgKbs"
                height="185"
                width="90%"
                allowFullScreen
              />
            </div>
          </div>
        </Hidden>
      </div>
    </React.Fragment>
  );
};

export default LFTTestimonials;
