import React, { Component } from "react";

import { makeStyles, ThemeProvider } from "@material-ui/styles";
import { MuiThemeProvider } from "@material-ui/core/styles";
// import Theme from "../hoc/Theme";
import { theme } from "../../utils/Theme";
import { Backdrop, CircularProgress } from "@material-ui/core";

function MainLoading(props) {
  const { root, Loader } = useStyles();

  return (
    <div className={root}>
      <Backdrop open={true} />
      <MuiThemeProvider theme={theme()}>
        <CircularProgress className={Loader} />
      </MuiThemeProvider>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    height: "100%",
    width: "100%",
    top: "0px",
    left: "0px",
    zIndex: "10000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  Loader: {
    position: "fixed",
    top: "50%",
    left: "50%",
    [theme.breakpoints.down("xs")]: {
      left: "45%"
    }
  }
}));

export default MainLoading;
