import React, { Component } from "react";

import { Grid, Button, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { PAGE_WIDTH } from "../utils/Constants";
import { CONTRIBUTE_URL } from "../utils/Urls";
import { COLORS } from "../utils/Theme";

import MainLoading from "../components/utils/MainLoading";

const styles = theme => ({
  root: {
    maxWidth: PAGE_WIDTH,
    padding: "16px",
    margin: "0 auto"
  },
  backBtn: {
    padding: "10px",
    background: "white",
    fontWeight: "bold"
  },
  mainImg: {
    padding: "0 16px 16px",
    width: PAGE_WIDTH,
    maxWidth: PAGE_WIDTH,
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },
  hashtag: {
    color: COLORS.PRIMARY_COLOR,
    padding: "8px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  container: {
    textAlign: "center",
    marginTop: window.innerHeight / 6,
    "& .MuiTypography-root": {
      margin: "12px"
    },
    [theme.breakpoints.up("md")]: {
      "& .MuiTypography-root": {
        lineHeight: "20px"
      }
    }
  },
  title: {
    color: COLORS.PRIMARY_COLOR,
    fontWeight: "bold",
    [theme.breakpoints.up("md")]: {
      fontSize: "20px"
    }
  },
  btnContainer: {
    marginTop: "100px"
  },
  tryAgain: {
    color: "white"
  }
});

class Failure extends Component {
  constructor(props) {
    super(props);
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.state = {
      userDetails,
      loading: false
    };
  }

  tryPlaceOrder = () => {
    const { userDetails } = this.state;
    this.setState({ loading: true });
    localStorage.setItem("userDetails", JSON.stringify({ ...userDetails }));
    fetch(CONTRIBUTE_URL, {
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({ ...userDetails })
    })
      .then(response => {
        return response.json();
      })
      .then(response => {
        if (response.result !== "success") {
          throw new Error(JSON.stringify(response));
        }

        var extras = response.extras || {};
        console.log("extras", extras);
        var pgurl = extras.pgparams.pgurl;
        var paymentparams = extras.postparams;

        // var payForm = document.createElement("form");
        // payForm.setAttribute("method", "post");
        // payForm.setAttribute("action", pgurl);
        window.location = pgurl;
        //   paymentparams.CALLBACK_URL = "https://app.thesmartq.com/paymentresponse";
        // for (var key in paymentparams) {
        //   var hiddenField = document.createElement("input");
        //   hiddenField.setAttribute("type", "hidden");
        //   hiddenField.setAttribute("name", key);
        //   hiddenField.setAttribute("value", paymentparams[key]);
        //   payForm.appendChild(hiddenField);
        // }

        // document.body.appendChild(payForm);
        // payForm.submit();
        // this.props.saveProfile();
      })
      .catch(error => {
        // Runs below code on unsuccessful fetch
        console.log(error);
      });
  };

  render() {
    const { classes, history } = this.props;
    const { loading } = this.state;

    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item xs md>
            <Button
              color="primary"
              className={classes.backBtn}
              onClick={() => history.push("/")}
            >
              Go Home
            </Button>
          </Grid>
          <Grid item>
            <Typography variant="h6" className={classes.hashtag}>
              #LetsFeedTogether
            </Typography>
          </Grid>
        </Grid>

        {/* <Grid container justify="center">
          <img src={mainImg} className={classes.mainImg} alt="Contribution" />
        </Grid> */}

        <Grid container justify="center" className={classes.container}>
          <Grid item xs={12} sm={12} md={8}>
            <Typography className={classes.title}>Oops!</Typography>
            <Typography variant="body1">
              Sorry. The transaction could not complete.
            </Typography>
            <Typography variant="body1">Please try again</Typography>
          </Grid>
        </Grid>

        <Grid container justify="center" className={classes.btnContainer}>
          <Grid item sm={8} xs={8} md={6}>
            <Button
              variant="contained"
              color="primary"
              className={classes.tryAgain}
              fullWidth
              onClick={this.tryPlaceOrder}
            >
              Try Again
            </Button>
          </Grid>
        </Grid>
        {loading && <MainLoading />}
      </div>
    );
  }
}

export default withStyles(styles)(Failure);
