import React from "react";

import { makeStyles } from "@material-ui/styles";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const styles = makeStyles((theme) => ({
  root: {
    width: "65%",
    margin: "auto",
    overflowY: "auto",
    marginBottom: "70px",
  },
  headerRow: {
    backgroundColor: "#ddd",
  },
  lightGreyBackground: {
    backgroundColor: "#f0f0f0",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 15px",
  },
  pageTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    paddingBottom: "15px",
  },
  headerText: {
    fontSize: "16px",
    fontWeight: "bold",
    opacity: "0.7",
  },
  valueText: {
    fontSize: "16px",
    opacity: "0.9",
  },
  tableContainer: {
    // boxShadow: "0px 0px 0px 3px #f0f0f0"
    border: "3px solid #f0f0f0",
  },
}));

const MealServedListDesktop = (props) => {
  const { details = [] } = props;
  const classes = styles();

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography variant="h6" className={classes.pageTitle}>
          Meals Served Tracker
        </Typography>
      </div>
      <TableContainer className={classes.tableContainer}>
        <Table aria-label="simple table">
          <TableHead className={classes.headerRow}>
            <TableRow>
              <TableCell className={classes.headerText}>Date</TableCell>
              {[
                "City",
                "Kitchen",
                "Meals",
                "Meal Detail",
                "Session",
                "Distributor",
                "Beneficiary",
              ].map((v, index) => (
                <TableCell
                  key={index}
                  align="center"
                  className={classes.headerText}
                >
                  {v}
                </TableCell>
              ))}
              {/* <TableCell align="center">City</TableCell>
              <TableCell align="center">Kitchen</TableCell>
              <TableCell align="center">Distributor</TableCell>
              <TableCell align="center">Beneficiary</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {details.map((row, index) => (
              <TableRow
                key={row.date}
                className={index % 2 === 0 && classes.lightGreyBackground}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.valueText}
                >
                  {row.date}
                </TableCell>
                <TableCell align="right" className={classes.valueText}>
                  {row.city}
                </TableCell>
                <TableCell align="right" className={classes.valueText}>
                  {row.kitchen}
                </TableCell>
                <TableCell align="right" className={classes.valueText}>
                  {row.no_of_meals}
                </TableCell>
                <TableCell align="right" className={classes.valueText}>
                  {row.meal_description}
                </TableCell>
                <TableCell align="right" className={classes.valueText}>
                  {row.sessions}
                </TableCell>
                <TableCell align="right" className={classes.valueText}>
                  {row.distributor}
                </TableCell>
                <TableCell align="center" className={classes.valueText}>
                  {row.beneficiary}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MealServedListDesktop;
