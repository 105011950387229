import React from "react";
import { makeStyles } from "@material-ui/styles";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { showAlert } from "../../store/actionCreators/utils";
import { JOIN_US_MSG } from "../../utils/Constants";

const styles = makeStyles(theme => ({
  container: {
    padding: "10px 0px 25px",
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px 15px"
    }
  },
  title: {
    fontWeight: "bold",
    padding: "20px 0px",
    textAlign: "center",
    color: theme.commonStyles.themeColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px",
      padding: "10px 0px"
    }
  },
  contentContainer: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  },
  content: {
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: "2.0",
    opacity: "0.7",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      paddingBottom: "15px"
    }
  },
  btn: {
    width: "325px",
    textTransform: "none",
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  subContainer: {
    width: "48%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      textAlign: "center"
      // padding: "0px 15px"
    }
  },
  btnContainer: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  }
}));

const JoinUs = props => {
  const classes = styles();
  const { showAlert } = props;

  const JoinHandler = () => {
    showAlert(JOIN_US_MSG);
    // props.history.push("/join-us");
  };

  return (
    <div className={classes.container} id="joinUs">
      {/* <Typography variant="h4" className={classes.title}>
        Join Us!
      </Typography> */}
      <div className={classes.contentContainer}>
        <div className={classes.subContainer}>
          <Typography variant="h6" className={classes.content}>
            If you are an NGO who wants to help in distribution
          </Typography>
        </div>
        <div className={classes.btnContainer}>
          <Button
            variant="contained"
            className={classes.btn}
            color="primary"
            onClick={JoinHandler}
          >
            Join hands with us!
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    showAlert: alertMessage => dispatch(showAlert({ alertMessage }))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JoinUs));
