import React, { Component } from "react";

import { Grid, Button, Typography } from "@material-ui/core";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  // FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon
  // LinkedinIcon
} from "react-share";
import { withStyles } from "@material-ui/core/styles";

import { PAGE_WIDTH } from "../utils/Constants";
import { COLORS } from "../utils/Theme";
import InstaIcon from "../assets/socialInstagramIcon.png";
import FacebookIcon from "../assets/socialFacebookIcon.png";
import LinkedinIcon from "../assets/socialLinkedinIcon.png";

const GET_URL_PARAMETER_BY_NAME = (name, url) => {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

const SHARE_CONFIG = [
  // {`
  //   button: FacebookShareButton,
  //   icon: FacebookIcon
  // },
  {
    button: TwitterShareButton,
    icon: TwitterIcon
  },
  // {
  //   button: LinkedinShareButton,
  //   icon: LinkedinIcon
  // },

  {
    button: WhatsappShareButton,
    icon: WhatsappIcon
  }
];

const styles = theme => ({
  root: {
    maxWidth: PAGE_WIDTH,
    padding: "16px",
    margin: "0 auto"
  },
  backBtn: {
    padding: "10px",
    background: "white",
    fontWeight: "bold"
  },
  mainImg: {
    padding: "0 16px 16px",
    width: PAGE_WIDTH,
    maxWidth: PAGE_WIDTH,
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },
  hashtag: {
    color: COLORS.PRIMARY_COLOR,
    padding: "8px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  container: {
    textAlign: "center",
    marginTop: window.innerHeight / 9,
    "& .MuiTypography-root": {
      margin: "16px"
    },
    [theme.breakpoints.up("md")]: {
      "& .MuiTypography-root": {
        lineHeight: "24px"
      }
    }
  },
  title: {
    color: COLORS.PRIMARY_COLOR,
    fontWeight: "bold",
    fontSize: "20px",
    [theme.breakpoints.up("md")]: {
      fontSize: "24px"
    }
  },
  shareMsg: {
    fontSize: "14px",
    lineHeight: "18px",
    color: COLORS.PRIMARY_COLOR
  },
  ShareButton: {
    display: "inline-block !important",
    outline: "none",
    cursor: "pointer",
    padding: "10px 0px",
    "& div": {
      display: "inline-block !important"
    }
  }
});

class Success extends Component {
  constructor(props) {
    super(props);

    localStorage.removeItem("userDetails");
    const orderid = GET_URL_PARAMETER_BY_NAME("orderid");
    const name = GET_URL_PARAMETER_BY_NAME("name");
    this.state = {
      name,
      orderid
    };

    this.shareEl = React.createRef();
    this.shareFb = React.createRef();
    this.shareLd = React.createRef();
  }

  triggerNative = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Lets Feed Together",
          text: "I made a difference with #LetsFeedTogether. You can, too, at ",
          url: window.location.origin
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(err => {
          console.log(`Couldn't share because of`, err.message);
        });
    } else {
      console.log("web share not supported");
    }
  };

  componentDidMount() {
    this.shareEl.current.addEventListener("click", this.triggerNative);
    this.shareFb.current.addEventListener("click", this.triggerNative);
    this.shareLd.current.addEventListener("click", this.triggerNative);
  }

  render() {
    const { classes, history } = this.props;
    const { name, orderid } = this.state;

    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item xs md>
            <Button
              color="primary"
              className={classes.backBtn}
              onClick={() => history.push("/")}
            >
              Go Home
            </Button>
          </Grid>
          <Grid item>
            <Typography variant="h6" className={classes.hashtag}>
              #LetsFeedTogether
            </Typography>
          </Grid>
        </Grid>

        {/* <Grid container justify="center">
          <img src={mainImg} className={classes.mainImg} alt="Contribution" />
        </Grid> */}

        <Grid container justify="center" className={classes.container}>
          <Grid item xs={12} sm={12} md={8}>
            <Typography className={classes.title}>Thank you {name}</Typography>
            <Typography variant="body1">
              Thank you for partnering with us. We will update you on your order
              status shortly.
            </Typography>

            <Typography variant="body1">
              Your unique #LetsFeedTogether order Id is
            </Typography>
            <Typography className={classes.title} style={{ margin: "-16px" }}>
              {orderid}
            </Typography>

            <Typography
              // variant="caption"
              style={{ marginTop: "100px" }}
              className={classes.shareMsg}
            >
              Share your act on social to inspire others to do the same.
            </Typography>
            <Grid container justify="center" style={{ marginBottom: "50px" }}>
              <Grid item xs={2} md={2} sm={2} style={{ textAlign: "center" }}>
                <img
                  ref={this.shareFb}
                  src={FacebookIcon}
                  width={40}
                  alt="Facebook Icon"
                />
              </Grid>
              <Grid item xs={2} md={2} sm={2} style={{ textAlign: "center" }}>
                <img
                  ref={this.shareLd}
                  src={LinkedinIcon}
                  width={40}
                  alt="Facebook Icon"
                />
              </Grid>
              {SHARE_CONFIG.map(item => (
                <Grid item xs={2} md={2} sm={2} style={{ textAlign: "center" }}>
                  <item.button
                    title={
                      "I made a difference with #LetsFeedTogether. You can, too, at "
                    }
                    quote={
                      "I made a difference with #LetsFeedTogether. You can, too, at "
                    }
                    url={"https://letsfeedtogether.com"}
                    className={classes.ShareButton}
                  >
                    {/* <item.button
                url={
                  "https://storage.googleapis.com/smartqdemo/foodinfo/sharetest.html"
                }
                className={ShareButton}
              > */}
                    <item.icon size={40} round={true} />
                  </item.button>
                </Grid>
              ))}
              <Grid item xs={2} md={2} sm={2} style={{ textAlign: "center" }}>
                <img
                  ref={this.shareEl}
                  src={InstaIcon}
                  width={40}
                  alt="Insta Icon"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Success);
