import React from "react";

import { makeStyles } from "@material-ui/core";

import AboutInitiative from "./LFTJourney/AboutInitiative";
import PartnerLogoShowcase from "./LFTJourney/PartnerLogoShowcase";
import LFTTestimonial from "./LFTJourney/LFTTestimonials";

const styles = makeStyles(theme => ({
  root: {
    width: "100%"
  }
}));

const LFTJourney = props => {
  const classes = styles();

  return (
    <div className={classes.root}>
      <AboutInitiative />
      <PartnerLogoShowcase />
      <LFTTestimonial />
    </div>
  );
};

export default LFTJourney;
