import { initialState } from "../initialState";
import { UPDATE_USER_CHOICES } from "../actions";

export default function userChoices(state = initialState.userChoices, action) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_USER_CHOICES:
      return { ...state, ...payload };
    default:
      return state;
  }
}
