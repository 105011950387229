import React, { Component } from "react";
import { connect } from "react-redux";
// import loadable from "react-loadable";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";

import { MuiThemeProvider } from "@material-ui/core/styles";

// import MainLoading from "./components/utils/MainLoading";
import { theme } from "./utils/Theme";
import { setFirebaseStaticData } from "./store/actionCreators/FirebaseStaticData";
import { FIREBASE_STATIC_DATA } from "./utils/Urls";
import "./App.css";

// const Home = loadable({
//   loader: () => import("./pages/Home"),
//   loading: () => <MainLoading />
// });

// const Contribute = loadable({
//   loader: () => import("./pages/Contribute"),
//   loading: () => <MainLoading />
// });

// const FundUsage = loadable({
//   loader: () => import("./pages/FundUsage"),
//   loading: () => <MainLoading />
// });

// const Success = loadable({
//   loader: () => import("./pages/Success"),
//   loading: () => <MainLoading />
// });

// const Failure = loadable({
//   loader: () => import("./pages/Failure"),
//   loading: () => <MainLoading />
// });

// const TnC = loadable({
//   loader: () => import("./pages/TnC"),
//   loading: () => <MainLoading />
// });

import Home from "./pages/Home";
import Contribute from "./pages/Contribute";
import FundUsage from "./pages/FundUsage";
import Success from "./pages/Success";
import Failure from "./pages/Failure";
import TnC from "./pages/TnC";
import JoinUs from "./pages/JoinUs";
import ContactUs from "./pages/ContactUs";
import Footer from "./components/Home/Footer";
import KnowMore from "./pages/KnowMore";
import MealsServed from "./components/Home/MealsServedList";
import FAQs from "./pages/FAQs";
import AlertPopup from "./components/utils/AlertPopup";
import ThankYouModal from "./components/Home/ThankYouModal";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  fetchFirebaseStaticData = () => {
    fetch(FIREBASE_STATIC_DATA, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        return response.json();
      })
      .then(response => {
        this.props.setFirebaseStaticData(response);
      });
  };

  componentDidMount() {
    this.fetchFirebaseStaticData();
  }

  render() {
    return (
      <MuiThemeProvider theme={theme()}>
        <div>
          <Switch>
            <Route path="/contribute" component={Contribute} />
            <Route path="/fundusage" component={FundUsage} />
            <Route path="/success" component={Success} />
            <Route path="/failure" component={Failure} />
            <Route path="/tnc" component={TnC} />
            <Route path="/know-more" component={KnowMore} />
            <Route path="/meals-served" component={MealsServed} />
            {/* <Route path="/join-us" component={JoinUs} /> */}
            {/* <Route path="/contact-us" component={ContactUs} /> */}
            <Route path="/faqs" component={FAQs} />
            <Route path="/" component={Home} />
          </Switch>
          <Footer />
          <AlertPopup />
          <ThankYouModal />
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    setFirebaseStaticData: data => {
      dispatch(setFirebaseStaticData(data));
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
