import React, { Component } from "react";
import IframeComponent from "../components/utils/Iframe";

const details = {
  url: "https://www.letsfeedtogether.com/lfttnc.html",
  title: "Terms and Conditions"
};

class TnC extends Component {
  // static getDerivedStateFromProps(nextProps, currState) {
  //   if (!nextProps.location.state) {
  //     return {
  //       modalOpen: false
  //     };
  //   }
  //   if (nextProps.location.state.modalOpen !== currState.modalOpen) {
  //     return {
  //       modalOpen: nextProps.location.state.modalOpen
  //     };
  //   }
  //   return null;
  // }
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: true
    };
  }

  render() {
    const { modalOpen } = this.state;

    return (
      <IframeComponent
        open={true}
        details={details}
        // handleClose={() => setfoodInfo(false)}
      />
    );
  }
}

export default TnC;
