import React, { useState } from "react";
import classnames from "classnames";

import { withRouter } from "react-router-dom";

import { makeStyles } from "@material-ui/styles";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import TickIcon from "../../assets/tick.png";

const CONTRIBUTION_OPTIONS = [
  { value: 50, label: "50 Meals" },
  { value: 100, label: "100 Meals" },
  { value: 500, label: "500 Meals" },
  { value: 1000, label: "1000 Meals" },
  { value: 5000, label: "5000 Meals" }
];

const styles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px 15px"
    }
  },
  main: {
    backgroundColor: theme.commonStyles.themeColorWithOpacity,
    padding: "10px 25px",
    marginTop: "20px",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px",
      marginTop: "10px"
    }
  },
  title: {
    textAlign: "center",
    fontSize: "24px",
    fontWeight: "bold",
    padding: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "8px",
      fontSize: "15px"
    }
  },
  gridItemContainer: {
    margin: "10px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `2px solid rgba(243, 112, 33, 0.4)`,
    borderRadius: "5px",
    fontWeight: "bold",
    color: "rgba(243, 112, 33, 0.6)",
    "&:hover": {
      cursor: "pointer"
    },
    padding: "6px"
  },
  activeGridContainer: {
    backgroundColor: "white",
    color: "rgb(243, 112, 33)",
    border: `2px solid rgba(243, 112, 33, 0.7)`
  },
  contributeBtn: {
    width: "325px",
    textTransform: "none",
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      width: "290px",
      marginTop: "15px"
    }
  },
  pricePerMeal: {
    textAlign: "right",
    color: theme.commonStyles.themeColor,
    fontWeight: "bold",
    padding: "15px 0px",
    [theme.breakpoints.down("sm")]: {
      // paddingBottom: "10px",
      padding: "5px",
      fontSize: "18px"
    }
  },
  btnContainer: {
    width: "100%",
    padding: "25px 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      textAlign: "center",
      padding: "0px"
    }
  },
  customValueField: {
    width: "230px",
    // border: `2px solid rgba(243, 112, 33, 0.7)`,
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  customTextFieldMobile: {
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  itemPadding: {
    [theme.breakpoints.down("sm")]: {
      padding: "6px"
    }
  }
}));

const Contribution = props => {
  const classes = styles();
  const [mealCount, setMealCount] = useState(500);
  // const [customMealCount, setCustomMealCount] = useState("");

  const navigateToContribute = () => {
    // console.log("mealcount or custommealcount", mealCount || customMealCount);
    props.history.push({
      pathname: "/contribute",
      state: {
        // mealCount: mealCount || customMealCount
        mealCount: mealCount
      }
    });
  };

  const handleMealCountClick = value => () => {
    // setCustomMealCount("");
    setMealCount(value);
  };

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <Typography variant="h6" className={classes.title}>
          How Many Will You Feed Today?
        </Typography>
        <Grid container justify="space-between" spacing={2}>
          {CONTRIBUTION_OPTIONS.map(option => {
            let isActive = option.value === parseInt(mealCount);

            return (
              <Grid
                item
                md={2}
                xs={5}
                key={option.value}
                className={classnames(
                  classes.gridItemContainer,
                  isActive && classes.activeGridContainer
                )}
                // style={{ padding: "6px" }}
                onClick={handleMealCountClick(option.value)}
              >
                {isActive && (
                  <img
                    src={TickIcon}
                    height="12px"
                    style={{ marginRight: "10px" }}
                    alt="tick"
                  />
                )}
                {option.label}
              </Grid>
            );
          })}
          <Grid item xs={5}>
            <TextField
              className={classes.customTextFieldMobile}
              label="Custom"
              variant="outlined"
              color="primary"
              size="small"
              value={mealCount}
              onChange={e => {
                setMealCount(e.target.value);
                // setCustomMealCount(e.target.value);
              }}
              type="number"
            />
          </Grid>
        </Grid>
        <div className={classes.btnContainer}>
          <TextField
            className={classes.customValueField}
            label="Custom"
            variant="outlined"
            color="primary"
            size="small"
            value={mealCount}
            onChange={e => {
              setMealCount(e.target.value);
              // setCustomMealCount(e.target.value);
            }}
            type="number"
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.contributeBtn}
            onClick={navigateToContribute}
            disabled={!mealCount}
          >
            Order Now
          </Button>
        </div>
        <Typography variant="h6" className={classes.pricePerMeal}>
          1 Meal = Rs.30/-
        </Typography>
      </div>
    </div>
  );
};

export default withRouter(Contribution);
