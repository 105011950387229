import React, { Component } from "react";
import WhyHowWhat from "../components/Home/WhyHowWhat";
import NavBar from "../components/Home/NavBar";

class KnowMore extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <NavBar />
        <WhyHowWhat />
      </>
    );
  }
}

export default KnowMore;
