import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import store from "./store/configureStore";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Helmet } from "react-helmet";

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
        <Helmet>
          <title>LetsFeedTogether</title>
          <meta
            name="description"
            content="#LetsFeedTogether is a platform created by Compass Group India to help in the fight for food security."
          />
          <meta
            name="keywords"
            content="letsfeedtogether, feedtheneedy, food-ordering"
          />
        </Helmet>
        <App />
      </React.StrictMode>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
