import React from "react";
import { makeStyles } from "@material-ui/styles";
import classnames from "classnames";
import CountUp from "react-countup";

import Typography from "@material-ui/core/Typography";

const styles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    fontSize: "24px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px"
    }
  },
  gridContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "15px 15px 0px"
    }
  },
  //   gridContainer: {
  //     textAlign: "center",
  //     padding: "10px"
  //   },
  gridItem: {
    borderRadius: "5px",
    width: "30%",
    padding: "10px",
    textAlign: "center",
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
      width: "25%"
    }
  },
  //   gridItem: {
  //     borderRadius: "5px"
  //   },
  type: {
    fontSize: "15px",
    opacity: 0.7,
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
      fontWeight: "bold"
    }
  },
  count: {
    fontSize: "28px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px"
    }
  },
  typeContributed: {
    // color: theme.commonStyles.themeColor
    color: "black"
  },
  countContributed: {
    color: theme.commonStyles.themeColor
  },
  contributedBox: {
    backgroundColor: "rgba(243, 112, 33, 0.1)"
  },
  toBeServedBox: {
    // backgroundColor: "rgba(0, 0, 136, 0.1)"
    backgroundColor: "rgba(243, 112, 33, 0.1)"
  },
  typeToBeServed: {
    // color: "rgb(0, 0, 136)"
    color: "black"
  },
  countToBeServed: {
    color: "rgb(18, 136, 7)"
  },
  servedBox: {
    // backgroundColor: "rgba(18, 136, 7, 0.1)"
    backgroundColor: "rgba(243, 112, 33, 0.1)"
  },
  typeServed: {
    // color: "rgb(18, 136, 7)"
    color: "black"
  },
  countServed: {
    color: "rgb(0, 0, 136)"
  },
  disclaimerContent: {
    fontSize: "14px",
    fontWeight: "bold",
    opacity: "0.7",
    paddingTop: "10px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      fontSize: "11px",
      paddingTop: "5px"
    }
  }
}));

const MealCount = (props) => {
  const { data } = props;
  const {
    meals_contributed = 0,
    meals_served = 0,
    meals_to_be_served = 0
  } = data;
  const classes = styles();

  const getFormattedNumber = (number) => {
    return new Intl.NumberFormat("en-IN").format(number);
  };

  return (
    <>
      {/* <div style={{ padding: "10px 0px 20px" }}>
        <Typography variant="h6" className={classes.title}>
          Meals ordered till now
        </Typography>
      </div> */}
      <div className={classes.gridContainer}>
        <div className={classnames(classes.gridItem, classes.contributedBox)}>
          <Typography
            className={classnames(classes.type, classes.typeContributed)}
            variant="body2"
          >
            Meals Ordered
          </Typography>
          <Typography
            className={classnames(classes.count, classes.countContributed)}
            variant="subtitle1"
          >
            {getFormattedNumber(parseInt(meals_contributed))}
          </Typography>
        </div>
        <div className={classnames(classes.gridItem, classes.servedBox)}>
          <Typography
            className={classnames(classes.type, classes.typeServed)}
            variant="body2"
          >
            Meals Served
          </Typography>
          <Typography
            className={classnames(classes.count, classes.countServed)}
            variant="subtitle1"
          >
            {getFormattedNumber(parseInt(meals_served))}
          </Typography>
        </div>
        <div className={classnames(classes.gridItem, classes.toBeServedBox)}>
          <Typography
            className={classnames(classes.type, classes.typeToBeServed)}
            variant="body2"
          >
            Meals Balance
          </Typography>
          <Typography
            className={classnames(classes.count, classes.countToBeServed)}
            variant="subtitle1"
          >
            {getFormattedNumber(parseInt(meals_to_be_served))}
          </Typography>
        </div>
      </div>
      <div style={{ width: "100%", textAlign: "left" }}>
        <Typography variant="h6" className={classes.disclaimerContent}>
          *Meals status since inception of #LFT
        </Typography>
      </div>
    </>
  );
};

export default MealCount;
