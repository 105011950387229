import React, { useState, useCallback } from "react";

import { makeStyles } from "@material-ui/styles";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
// import IconButton from "@material-ui/core/IconButton";

// import ViewMoreIcon from "@material-ui/icons/Visibility";
import Hidden from "@material-ui/core/Hidden";

import FullMealServedDetail from "./FullMealServedDetail";

const styles = makeStyles(theme => ({
  mobileRoot: {
    padding: "15px",
    borderRadius: "10px",
    backgroundColor: "#f0f0f0",
    textAlign: "left",
    marginBottom: "10px",
    position: "relative",
    "&:hover": {
      cursor: "pointer"
    }
  },
  value: {
    fontSize: "15px",
    fontWeight: "bold",
    opacity: "0.58",
    lineHeight: "1.25",
    wordBreak: "break-word"
  },
  title: {
    fontSize: "13px",
    fontWeight: "bold",
    opacity: "0.4",
    lineHeight: "1.25"
  },
  viewMoreIcon: {
    [theme.breakpoints.up("sm")]: {
      display: "none"
    },
    position: "absolute",
    top: "0px",
    right: "0px"
  },
  viewMoreText: {
    // [theme.breakpoints.up("sm")]: {
    //   display: "none"
    // },
    fontSize: "12px",
    opacity: "0.5",
    fontWeight: "bold",
    paddingBottom: "10px"
  }
}));

const TitleValueCard = props => {
  const { title, value } = props;

  const classes = styles();

  return (
    <>
      <div style={{ paddingBottom: "10px" }}>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="h6" className={classes.value}>
          {value}
        </Typography>
      </div>
    </>
  );
};

const MobileDisplayCard = props => {
  // const { data = {}, showViewMore = false } = props;
  const { data = {} } = props;
  const { date, beneficiary, kitchen, city, distributor, no_of_meals } = data;

  const [toggleViewMore, setToggleViewMore] = useState(false);

  const classes = styles();

  const toggleFullDetail = useCallback(
    () => setToggleViewMore(!toggleViewMore),
    [toggleViewMore]
  );

  return (
    <>
      <div className={classes.mobileRoot} onClick={toggleFullDetail}>
        <Typography variant="h6" className={classes.viewMoreText}>
          Tap the card to view more details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TitleValueCard title="Date" value={date} />
          </Grid>
          <Grid item xs={6}>
            <TitleValueCard title="Meals" value={no_of_meals} />
          </Grid>
          <Grid item xs={6}>
            <TitleValueCard title="City" value={city} />
          </Grid>
          <Grid item xs={6}>
            <TitleValueCard title="Distributed by" value={distributor} />
          </Grid>
          <Grid item xs={6}>
            <TitleValueCard title="Kitchen" value={kitchen} />
          </Grid>
          <Grid item xs={6}>
            <TitleValueCard title="Beneficiary" value={beneficiary} />
          </Grid>
        </Grid>
      </div>
      {toggleViewMore && (
        <FullMealServedDetail
          open={toggleViewMore}
          details={data}
          closeDialog={toggleFullDetail}
        />
      )}
    </>
  );
};

export default MobileDisplayCard;
