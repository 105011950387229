import React from "react";

import { withRouter } from "react-router-dom";

import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { COLORS } from "../../../utils/Theme";

const styles = makeStyles(theme => ({
  root: {
    padding: "15px"
  },
  title: {
    padding: "15px",
    fontWeight: "bold",
    textAlign: "center"
  },
  contentWithLink: {
    fontSize: "17px",
    fontWeight: "400",
    padding: "17px 0px"
  },
  customDiv: {
    padding: "10px 0px"
  },
  commonContentStyles: {
    // fontWeight: "bold"
  }
}));

const CustomDiv = props => {
  const classes = styles();

  return (
    <div className={classes.customDiv}>
      <Typography
        variant="subtitle1"
        {...props}
        className={classes.commonContentStyles}
      >
        {props.children}
      </Typography>
    </div>
  );
};

const AboutInitiative = props => {
  const classes = styles();

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        Compass Group India’s ‘Lets Feed Together’ Initiative
      </Typography>
      <CustomDiv>
        Compass Group India is a wholly owned subsidiary of Compass Group PLC
        and has been servicing the Indian Workplace, Healthcare and Education
        market needs since 2008.
      </CustomDiv>
      <CustomDiv>
        Compass Group India’s flagship concept <b>#LetsFeedTogether</b> has
        helped spread cheer to more than 3 million people in one month.
      </CustomDiv>
      <CustomDiv>
        Food security had become a problem in India immediately post the
        announcement of lockdown across the country. The poor and
        underprivileged were the most impacted by this announcement as most of
        them depended on daily wages to make a living.
      </CustomDiv>
      <CustomDiv>
        Being a food service company, Compass Group India wanted to be a part of
        the solution. The company announced the #LetsFeedTogether initiative,
        which brought together three key stakeholders:
      </CustomDiv>
      <List>
        <ListItem>
          <ListItemText primary="1. The customer (placing order)" />
        </ListItem>
        <ListItem>
          <ListItemText primary="2. Compass Group India (CGI) and partner kitchens and" />
        </ListItem>
        <ListItem>
          <ListItemText primary="3. Distribution partners (government authorities, organizations and NGOs), to provide food to the needy" />
        </ListItem>
      </List>
      <CustomDiv>
        The platform was a key initiative in the company’s efforts to pledge its
        support to the people of India during the trying times. It was
        heartwarming to see the response received in such a short span of time.
        It was only possible due to combined efforts of all stakeholders.
      </CustomDiv>
      <CustomDiv>
        CGI prepared fresh, wholesome single pot dishes such as Achari Daal
        Khichdi with Vegetables, Vegetable Sambhar Rice and Tomato Khichdi with
        Vegetables that were packed hot, in suitable material to keep them as
        fresh as possible. These packed meals were then delivered to the needy
        through distribution partners.
      </CustomDiv>
      <CustomDiv>
        All customers who had ordered meals through the #LetsFeedTogether
        platform receive an update about where their meals were distributed.
      </CustomDiv>
      <CustomDiv>
        The cities where CGI has kitchen operations are Delhi NCR, Bangalore,
        Mumbai, Hyderabad and Chennai, and thus these cities saw the company’s
        efforts focused towards bringing relief to the needy.
      </CustomDiv>
      <span className={classes.contentWithLink}>
        To know more about #LetsFeedTogether, please click{" "}
        <p
          style={{
            textDecoration: "underline",
            color: COLORS.PRIMARY_COLOR,
            margin: "0px",
            display: "inline",
            cursor: "pointer",
            fontWeight: "bold"
          }}
          onClick={() => props.history.push("/know-more")}
        >
          here
        </p>
      </span>
    </div>
  );
};

export default withRouter(AboutInitiative);
