import React from "react";

import { makeStyles } from "@material-ui/styles";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { COLORS } from "../../utils/Theme";

import { Carousel } from "react-responsive-carousel";

const styles = makeStyles(theme => ({
  root: {
    padding: "15px",
    borderRadius: "5px"
  },
  dialogPaper: {
    borderRadius: "10px",
    height: "90%",
    [theme.breakpoints.up("sm")]: {
      width: "30%"
    }
  },
  imageContainer: {
    height: "180px",
    padding: "10px 0px"
  },
  label: {
    fontSize: "16px",
    fontWeight: "bold",
    // color: theme.commonStyles.themeColor
    opacity: "0.5"
  },
  value: {
    fontSize: "14px",
    fontWeight: "bold",
    opacity: "0.8",
    paddingTop: "2px"
  },
  actionContainer: {
    boxShadow: "1px 0px 4px 1px rgba(0,0,0,0.65)"
  },
  titleContainer: {
    // boxShadow: "1px 0px 4px 1px rgba(0,0,0,0.65)"
  },
  dialogTitle: {
    color: theme.commonStyles.themeColor,
    fontWeight: "bold"
  },
  container: {
    width: "100%",
    overflowX: "auto",
    whiteSpace: "nowrap",
    paddingBottom: "15px"
  },
  individualImgContainer: {
    display: "inline-block",
    paddingRight: "25px",
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "80%"
    }
  },
  imageStyles: {
    width: "100%",
    height: "190px",
    borderRadius: "15px"
  }
}));

const IndividualDetail = props => {
  const classes = styles();
  const { name = "", value = "" } = props;

  return (
    <>
      <Grid item xs={4}>
        <Typography variant="h6" className={classes.label}>
          {name}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="h6" className={classes.value}>
          {value}
        </Typography>
      </Grid>
    </>
  );
};

const FullMealServedDetail = props => {
  const classes = styles();
  const { details = {}, open, closeDialog } = props;
  const {
    images,
    city,
    distributor,
    kitchen,
    beneficiary,
    date,
    no_of_meals,
    meal_description,
    sessions
  } = details;

  return (
    <Dialog
      fullScreen
      open={open}
      classes={{
        root: classes.root,
        paper: classes.dialogPaper
      }}
    >
      <DialogTitle
        classes={{
          root: classes.titleContainer
        }}
      >
        <Typography variant="h6" className={classes.dialogTitle}>
          Details
        </Typography>
      </DialogTitle>
      <DialogContent>
        {/* <Typography variant="h6" className={classes.dialogTitle}>
          Details
        </Typography> */}
        <Grid container spacing={2} style={{ padding: "15px 0px" }}>
          {date && <IndividualDetail name="Date" value={date} />}
          {no_of_meals && <IndividualDetail name="Meals" value={no_of_meals} />}
          {city && <IndividualDetail name="City" value={city} />}
          {kitchen && <IndividualDetail name="Kitchen" value={kitchen} />}
          {meal_description && (
            <IndividualDetail name="Meal Detail" value={meal_description} />
          )}
          {sessions && <IndividualDetail name="Session" value={sessions} />}
          {distributor && (
            <IndividualDetail name="Distributed by" value={distributor} />
          )}
          {beneficiary && (
            <IndividualDetail name="Beneficiary" value={beneficiary} />
          )}
        </Grid>
        <Carousel showThumbs={false} showIndicators={false}>
          {images && (
            <div className={classes.container}>
              {images
                .replace(" ", "")
                .split(",")
                .map(image => (
                  <img src={image} alt={image.split("/").pop()} />
                ))}
            </div>
          )}
        </Carousel>
      </DialogContent>
      <DialogActions
        classes={{
          root: classes.actionContainer
        }}
      >
        <Button
          variant="text"
          onClick={closeDialog}
          style={{ color: COLORS.PRIMARY_COLOR, fontWeight: "bold" }}
        >
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FullMealServedDetail;
