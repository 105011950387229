import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Iframe from "react-iframe";

import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import { COLORS } from "../../utils/Theme";

const useStyles = makeStyles(theme => ({
  root: {
    // maxWidth: "450px",
    // width: "100%",
    // height: "100%",
    // maxHeight: "700px",
    // borderRadius: "10px",
    // position: "relative",
    // [theme.breakpoints.down("md")]: {
    //   maxWidth: "100%",
    //   maxHeight: "100%",
    //   position: "fixed",
    //   left: "0px",
    //   top: "0px",
    //   margin: "0px",
    //   borderRadius: "0"
    // }
  },
  Header: {
    backgroundColor: COLORS.PRIMARY_COLOR,
    padding: "10px 20px",
    // position: "sticky",
    top: "0px",
    left: "0px"
  },
  IframeContainer: {
    width: "100%",
    height: "100%",
    "-webkit-overflow-scrolling": "touch",
    overflowY: "auto",
    position: "relative",
    backgroundColor: COLORS.primary
  },
  RootDialog: {
    zIndex: "9999"
  }
}));

const IframeComponent = props => {
  const classes = useStyles();
  const { open, details } = props;
  const { root, Header, IframeContainer, RootDialog } = classes;
  const { url = "", title = "" } = details;

  const handleClose = () => {
    props.history.goBack();
  };

  return (
    <Dialog
      fullScreen
      onClose={handleClose}
      open={open}
      classes={{
        root: RootDialog,
        paper: root
      }}
    >
      {/* <Grid container className={Header}>
        <Grid item xs style={{ fontWeight: "bold" }}>
          {title}
        </Grid>
        <Grid item style={{ cursor: "pointer" }} onClick={handleClose}>
          <CloseIcon />
        </Grid>
      </Grid> */}

      <div className={IframeContainer}>
        <Iframe
          width="100%"
          height="100%"
          url={url}
          id="urlFrame"
          style={{ border: "none" }}
          frameBorder="0"
        />
      </div>
    </Dialog>
  );
};

export default withRouter(IframeComponent);
