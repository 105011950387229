import { initialState } from "../initialState";
import { SET_FIREBASE_STATIC_DATA } from "../actions";

export default function setFirebaseStaticData(
  state = initialState.firebaseStaticData,
  action
) {
  switch (action.type) {
    case SET_FIREBASE_STATIC_DATA:
      return action.payload;
    default:
      return state;
  }
}
