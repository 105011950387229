import React from "react";

import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";

import DesktopImageShowcase from "../DesktopImageShowcase";

import ImageOne from "../../../assets/squooshed1.jpeg";
import ImageTwo from "../../../assets/squooshed2.jpeg";
import ImageThree from "../../../assets/squooshed3.jpeg";
import ImageFour from "../../../assets/squooshed4.jpeg";
import ImageFive from "../../../assets/squooshed5.jpeg";
import ImageSix from "../../../assets/squooshed6.jpeg";
import ActLogo from "../../../assets/act-fibernet-logo.jpg";
import ActionAidLogo from "../../../assets/actionaid-logo.jpg";
import AzimPremjiLogo from "../../../assets/azim-premji-philanthropic-initiatives.png";
import DelhiGovtLogo from "../../../assets/delhi-government-logo.png";
import IhroLogo from "../../../assets/ihro-logo.jpg";
import KtakaGovtLogo from "../../../assets/karnataka-government-logo.jpg";
import JllLogo from "../../../assets/JLL-logo.png";
import MGHectorLogo from "../../../assets/mg-motors-logo.png";
import MkEduLogo from "../../../assets/mk-educational-society-logo.png";
import SignatureFoodsLogo from "../../../assets/signature-foods-logo.jpg";
import SonaLogo from "../../../assets/sona-sarovar-trust-logo.jpg";
import SwiggyLogo from "../../../assets/swiggy-logo.jpg";
import TitanLogo from "../../../assets/titan-logo.png";

const IMG_LIST = [
  KtakaGovtLogo,
  SwiggyLogo,
  DelhiGovtLogo,
  AzimPremjiLogo,
  MGHectorLogo,
  TitanLogo,
  ActLogo,
  SignatureFoodsLogo,
  MkEduLogo,
  IhroLogo,
  JllLogo,
  SonaLogo,
  ActionAidLogo,
  ImageOne,
  ImageTwo,
  ImageThree,
  ImageFour,
  ImageFive,
  ImageSix
];

const styles = makeStyles(theme => ({
  titleContainer: {
    padding: "0px 15px"
  },
  container: {
    width: "100%",
    overflowX: "auto",
    whiteSpace: "nowrap",
    padding: "15px 0px"
  },
  individualImgContainer: {
    display: "inline-block",
    padding: "0px 25px",
    width: "40%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "auto"
    }
  },
  imageStyles: {
    width: "auto",
    height: "100px",
    borderRadius: "15px"
  }
}));

const ImageDisplay = props => {
  const classes = styles();
  const { details } = props;

  return (
    <div className={classes.individualImgContainer}>
      <img
        src={details}
        alt={details.split("/").pop()}
        className={classes.imageStyles}
      />
    </div>
  );
};

const PartnerLogoShowcase = () => {
  const classes = styles();

  return (
    <React.Fragment>
      <Hidden smDown>
        <DesktopImageShowcase
          imgWidth="auto"
          imgHeight="140px"
          images={IMG_LIST}
          centerSlidePercentage={50}
        />
      </Hidden>
      <Hidden mdUp>
        <div className={classes.container}>
          {IMG_LIST.map(image => (
            <ImageDisplay details={image} key={image.split("/").pop()} />
          ))}
        </div>
      </Hidden>
    </React.Fragment>
  );
};

export default PartnerLogoShowcase;
