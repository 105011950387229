import React from "react";

import {
  withStyles,
  MuiThemeProvider,
  withTheme
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";

import { removeAlert } from "../../store/actionCreators/utils";

import { theme } from "../../utils/Theme";

import { connect } from "react-redux";

class alertPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.message !== "") {
      this.setState({ show: true });
    } else {
      this.setState({ show: false });
    }
  }
  render() {
    const { show } = this.state;
    const { message } = this.props;
    return (
      <MuiThemeProvider theme={theme()}>
        <Dialog
          open={show}
          fullWidth
          maxWidth="xs"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Typography variant="subheading">
              {message || "Oops! Something went wrong"}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.props.removeAlert()}
              color="primary"
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    message: state.utils.alertMessage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    removeAlert: () => {
      dispatch(removeAlert({ alertMessage: "" }));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles({ withTheme: true })(alertPopup));
