import React from "react";

import { makeStyles } from "@material-ui/styles";

import Typography from "@material-ui/core/Typography";

const styles = makeStyles(theme => ({
  footer: {
    width: "100%",
    textAlign: "center",
    backgroundColor: theme.commonStyles.greyShade,
    padding: "15px",
    position: "fixed",
    bottom: "0px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0px"
    }
  },
  foooterText: {
    fontSize: "16px",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px"
    }
  }
}));

const Footer = () => {
  const classes = styles();

  return (
    <div className={classes.footer}>
      <Typography variant="subtitle2" className={classes.foooterText}>
        A Compass Group India Initiative, Tech Powered by SmartQ
      </Typography>
    </div>
  );
};

export default Footer;
