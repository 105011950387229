import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/styles";

import Typography from "@material-ui/core/Typography";
// import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { useState } from "react";

import MobileDisplayCard from "./MealDataDisplayCardMobile";

// const DATA_LIMIT = 100;
const ITEM_FETCH_LIMIT = 15;

const styles = makeStyles((theme) => ({
  mobileRoot: {
    padding: "15px",
    borderRadius: "10px",
    backgroundColor: "#f0f0f0",
    textAlign: "left",
    marginBottom: "10px",
  },
  extraDetailsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  subContainer: {
    display: "flex",
    flexDirection: "column",
  },
  value: {
    fontSize: "15px",
    fontWeight: "bold",
    opacity: "0.58",
    lineHeight: "1.25",
    wordBreak: "break-word",
  },
  title: {
    fontSize: "13px",
    fontWeight: "bold",
    opacity: "0.4",
    lineHeight: "1.25",
  },
  container: {
    padding: "10px",
    marginBottom: "30px",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 15px 0px",
  },
  pageTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  alignTextCenter: {
    textAlign: "center",
  },
  loadMoreBtn: {
    color: theme.commonStyles.themeColor,
    width: "150px",
    padding: "10px",
  },
  endContainer: {
    textAlign: "center",
    margin: "10px 0px",
  },
}));

// const TitleValueCard = props => {
//   const { title, value } = props;

//   const classes = styles();

//   return (
//     <>
//       <div style={{ paddingBottom: "10px" }}>
//         <Typography variant="h6" className={classes.title}>
//           {title}
//         </Typography>
//         <Typography variant="h6" className={classes.value}>
//           {value}
//         </Typography>
//       </div>
//     </>
//   );
// };

// const MobileDisplayCard = props => {
//   const { data = {} } = props;
//   const { date, beneficiary, kitchen, city, distributor, no_of_meals } = data;

//   const classes = styles();

//   return (
//     <div className={classes.mobileRoot}>
//       <Grid container spacing={2}>
//         <Grid item xs={6}>
//           <TitleValueCard title="Date" value={date} />
//         </Grid>
//         <Grid item xs={6}>
//           <TitleValueCard title="Meals" value={no_of_meals} />
//         </Grid>
//         <Grid item xs={6}>
//           <TitleValueCard title="City" value={city} />
//         </Grid>
//         <Grid item xs={6}>
//           <TitleValueCard title="Distributed by" value={distributor} />
//         </Grid>
//         <Grid item xs={6}>
//           <TitleValueCard title="Kitchen" value={kitchen} />
//         </Grid>
//         <Grid item xs={6}>
//           <TitleValueCard title="Beneficiary" value={beneficiary} />
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

const MealServedListMobile = (props) => {
  const { details = [] } = props;
  const classes = styles();

  // const [mealsData, setMealsData] = useState(details);
  const [startIndex, setStartIndex] = useState(ITEM_FETCH_LIMIT);
  const [endIndex, setEndIndex] = useState(startIndex + ITEM_FETCH_LIMIT);
  const [itemsToDisplay, setItemsToDisplay] = useState([]);

  const fetchData = () => {
    if (
      // itemsToDisplay.length >= DATA_LIMIT ||
      itemsToDisplay.length >= details.length
    )
      return;

    // setTimeout(() => {
    let newItemsList = [...itemsToDisplay].concat(
      [...details].slice(startIndex, endIndex)
    );
    let newStartIndex = endIndex;
    let newEndIndex = endIndex + ITEM_FETCH_LIMIT;

    setItemsToDisplay(newItemsList);
    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);
    // }, 500);
  };

  useEffect(() => {
    setItemsToDisplay([...details].slice(0, ITEM_FETCH_LIMIT));
    window.scrollTo(0, 0);
  }, [details.length, details]);

  return (
    <>
      <div className={classes.titleContainer}>
        <Typography variant="h6" className={classes.pageTitle}>
          Meals Served Tracker
        </Typography>
      </div>
      <div className={classes.container}>
        {itemsToDisplay.map((detail, index) => (
          <MobileDisplayCard key={index} data={detail} showViewMore />
        ))}
        {itemsToDisplay.length === details.length ? (
          <div className={classes.endContainer}>
            <Typography variant="h6" className={classes.pageTitle}>
              ----- End -----
            </Typography>
          </div>
        ) : (
          <div className={classes.endContainer}>
            <Button
              variant="text"
              onClick={fetchData}
              className={classes.loadMoreBtn}
            >
              Load More
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default MealServedListMobile;
