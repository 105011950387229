import { combineReducers } from "redux";

import userChoices from "./reducers/userChoices";
import utils from "./reducers/utils";

import firebaseStaticData from "./reducers/FirebaseStaticData";

export default combineReducers({
  userChoices,
  utils,
  firebaseStaticData
});
