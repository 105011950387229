import React, { Component } from "react";
import IframeComponent from "../components/utils/Iframe";

const details = {
  url: "https://abhishek902765.typeform.com/to/b6d27i",
  title: "Contact Us"
};

class ContactUs extends Component {
  render() {
    return (
      <IframeComponent
        open={true}
        details={details}
        // handleClose={() => setfoodInfo(false)}
      />
    );
  }
}

export default ContactUs;
