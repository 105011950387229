const domainName = window.location.hostname;
export let BASE_URL = ".";

if (domainName === "localhost") {
  BASE_URL = "https://www.letsfeedtogether.com";
}

export const FIREBASE_STATIC_DATA =
  "https://letsfeedtogether-prd.firebaseio.com/.json";

export const GET_STATS_URL = BASE_URL + "/api/stats";
export const CONTRIBUTE_URL = BASE_URL + "/api/contribute";
