import { createMuiTheme } from "@material-ui/core/styles";

export const COLORS = {
  PRIMARY_COLOR: "#f37021",
  TEXT_COLOR: "#fff",
  TEXT_DISABLED: "#aaa"
};

export const theme = () => {
  return createMuiTheme({
    palette: {
      primary: { main: COLORS.PRIMARY_COLOR }
    },
    typography: {
      fontFamily: "Roboto Slab"
    },
    overrides: {
      MuiButton: {
        root: {
          // backgroundColor: COLORS.PRIMARY_COLOR,
          borderRadius: "5px",
          "&$disabled": {
            backgroundColor: COLORS.PRIMARY_COLOR,
            color: COLORS.TEXT_DISABLED,
            opacity: 0.7
          }
        },
        containedPrimary: {
          color: COLORS.TEXT_COLOR
        },
        outlinedPrimary: {
          color: COLORS.TEXT_COLOR
        }
      }
    },
    commonStyles: {
      themeColor: COLORS.PRIMARY_COLOR,
      themeColorWithOpacity: `rgba(243, 112, 33, 0.1)`,
      greyShade: "#424143"
    }
    // ---------------------------------------------
  });
};
