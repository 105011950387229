import React from "react";
import { withRouter } from "react-router-dom";
// import classnames from "classnames";

import { makeStyles } from "@material-ui/styles";

import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";

import DesktopImageShowcase from "./DesktopImageShowcase";

import "react-responsive-carousel/lib/styles/carousel.min.css";

const styles = makeStyles(theme => ({
  carouselRoot: {
    // [theme.breakpoints.up("sm")]: {
    //   display: "none"
    // }
  },
  container: {
    width: "100%",
    overflowX: "auto",
    whiteSpace: "nowrap",
    padding: "15px 0px"
  },
  individualImgContainer: {
    display: "inline-block",
    paddingRight: "25px",
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "80%"
    }
  },
  imageStyles: {
    width: "100%",
    height: "190px",
    borderRadius: "15px"
  },
  titleContainer: {
    // display: "flex",
    // justifyContent: "space-between",
    // alignItems: "center",
    padding: "15px 15px 0px"
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px"
    }
  },
  moreInfo: {
    textDecoration: "underline",
    color: theme.commonStyles.themeColor,
    "&:hover": {
      cursor: "pointer"
    }
  }
}));

const ImageDisplay = props => {
  const classes = styles();
  const { details } = props;

  return (
    <div className={classes.individualImgContainer}>
      <img
        src={details}
        alt={details.split("/").pop()}
        className={classes.imageStyles}
      />
    </div>
  );
};

const Title = props => {
  const classes = styles();
  const { images = [] } = props;

  return (
    images.length > 0 && (
      <Typography variant="h6" className={classes.title}>
        Glimpses
      </Typography>
    )
  );
};

const ImageShowCase = props => {
  const { data = {} } = props;
  const { images = [] } = data;

  const classes = styles();

  return (
    <div className={classes.carouselRoot}>
      <Hidden smDown>
        <Title images={images} />
        <DesktopImageShowcase images={images} />
      </Hidden>
      <div className={classes.titleContainer}>
        <Hidden mdUp>
          <Title images={images} />
          <div className={classes.container}>
            {images.map(image => (
              <ImageDisplay details={image} key={image.split("/").pop()} />
            ))}
          </div>
        </Hidden>
      </div>
    </div>
  );
};

export default withRouter(ImageShowCase);
