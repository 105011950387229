import React, { Component } from "react";

import {
  Grid,
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Link,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { PAGE_WIDTH } from "../utils/Constants";
import { CONTRIBUTE_URL } from "../utils/Urls";
import { COLORS } from "../utils/Theme";
import mainImg from "../assets/contributionMainImage.png";

import MainLoading from "../components/utils/MainLoading";

const styles = (theme) => ({
  root: {
    maxWidth: PAGE_WIDTH,
    padding: "16px",
    margin: "0 auto",
  },
  backBtn: {
    padding: "10px",
    background: "white",
    fontWeight: "bold",
  },
  hashtag: {
    color: COLORS.PRIMARY_COLOR,
    padding: "8px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  mainImg: {
    padding: "0 16px 16px",
    width: PAGE_WIDTH,
    height: PAGE_WIDTH / 3,
    maxWidth: PAGE_WIDTH,
    maxHeight: PAGE_WIDTH / 3,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxHeight: window.innerWidth / 3,
    },
  },
  radioGroup: {
    margin: `${theme.spacing.unit}px 0px 16px`,
    flexDirection: "row",
    "& .MuiFormControlLabel-root": {
      background: "#f0f0f0",
      borderRadius: "8px",
      width: "100%",
      height: "56px",
      marginLeft: "0",
    },
  },
  raidoBtn: {
    paddingLeft: "24px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "12px",
    },
  },
  form: {
    "& .MuiTextField-root": {
      background: "#f0f0f0",
      // borderRadius: "12px"
    },
  },
  tncStatement: {
    color: "black",
    "&:hover": {
      cursor: "pointer",
    },
  },
  placeorderBtn: {
    marginTop: "16px",
    marginBottom: "50px",
    color: "white",
    borderRadius: "12px",
    padding: "12px",

    "&:disabled": {
      background: COLORS.PRIMARY_COLOR,
      color: "black",
    },
  },
});

class Contribute extends Component {
  constructor(props) {
    super(props);
    console.log("props.location", props.location);
    const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {};

    const locState = (props.location || {}).state || {};
    this.state = {
      typeofcontributor: "individual",
      name: "",
      mobileno: "",
      emailid: "",
      pan: "",
      gst: "",
      tncAccepted: false,
      disableButton: true,
      loading: false,
      ...userDetails,
      no_of_meals: locState.mealCount || userDetails.mealCount || 10,
      amount: (locState.mealCount || userDetails.mealCount || 10) * 30,
    };
  }

  handleChange = (event) => {
    const { name, value, checked } = event.target;

    // let numberRegex = /^[0-9]*$/;
    // if (name === "no_of_meals" && value) {
    //   if (!numberRegex.test(value)) return;
    // }

    let updateObj = { [name]: value };
    if (name === "tncAccepted") updateObj = { [name]: checked };

    if (name === "no_of_meals") updateObj.amount = value * 30;
    this.setState(updateObj, () => {
      let disableButton = true;
      disableButton = this.checkButtonStatus();
      this.setState({ disableButton });
    });
  };

  tncHandler = (event) => {
    event.preventDefault();
    localStorage.setItem("userDetails", JSON.stringify({ ...this.state }));
    this.props.history.push("/tnc");
  };

  toggleTermsAndConditions = () => {
    this.setState({ tncAccepted: !this.state.tncAccepted }, () => {
      let disableButton = true;
      disableButton = this.checkButtonStatus();
      this.setState({ disableButton });
    });
  };

  checkButtonStatus = () => {
    const {
      typeofcontributor,
      name,
      mobileno,
      emailid,
      no_of_meals,
      amount,
      gst,
      pan,
      tncAccepted,
    } = this.state;
    if (!mobileno || !emailid || !no_of_meals || !tncAccepted) return true;
    if (typeofcontributor === "individual") {
      if (!name) return true;
      if (amount < 0) return true;
      if (amount >= 50000 && !pan) return true;
    } else {
      if (!gst) return true;
    }
    return false;
  };

  placeOrder = () => {
    this.setState({ loading: true });

    localStorage.setItem("userDetails", JSON.stringify({ ...this.state }));
    fetch(CONTRIBUTE_URL, {
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ ...this.state }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response.result !== "success") {
          throw new Error(JSON.stringify(response));
        }

        var extras = response.extras || {};
        console.log("extras", extras);
        var pgurl = extras.pgparams.pgurl;
        var paymentparams = extras.postparams;

        // var payForm = document.createElement("form");
        // payForm.setAttribute("method", "post");
        // payForm.setAttribute("action", pgurl);
        window.location = pgurl;
        //   paymentparams.CALLBACK_URL = "https://app.thesmartq.com/paymentresponse";
        // for (var key in paymentparams) {
        //   var hiddenField = document.createElement("input");
        //   hiddenField.setAttribute("type", "hidden");
        //   hiddenField.setAttribute("name", key);
        //   hiddenField.setAttribute("value", paymentparams[key]);
        //   payForm.appendChild(hiddenField);
        // }

        // document.body.appendChild(payForm);
        // payForm.submit();
        // this.props.saveProfile();
      })
      .catch((error) => {
        // Runs below code on unsuccessful fetch
        console.log(error);
      });
    window.gtag_report_conversion();
  };

  render() {
    const { classes, history } = this.props;
    console.log("this.props", this.props);
    const {
      typeofcontributor,
      name,
      mobileno,
      emailid,
      no_of_meals,
      amount,
      pan,
      gst,
      tncAccepted,
      disableButton,
      loading,
    } = this.state;
    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item xs md>
            <Button
              color="primary"
              className={classes.backBtn}
              startIcon={<ArrowLeft style={{ margin: 0 }} />}
              onClick={() => history.goBack()}
            >
              Go Back
            </Button>
          </Grid>
          <Grid item>
            <Typography variant="h6" className={classes.hashtag}>
              #LetsFeedTogether
            </Typography>
          </Grid>
        </Grid>

        <Grid container justify="center">
          <img src={mainImg} className={classes.mainImg} alt="Contribution" />
        </Grid>

        <Typography variant="h6">Order Form</Typography>
        <FormControl component="fieldset" fullWidth>
          <RadioGroup
            aria-label="typeofcontributor"
            name="typeofcontributor"
            className={classes.radioGroup}
            value={typeofcontributor}
            onChange={this.handleChange}
          >
            <Grid container flexDirection="row" spacing={3}>
              <Grid item xs={6} md={6} sm={6}>
                <FormControlLabel
                  value="individual"
                  control={
                    <Radio color="primary" className={classes.raidoBtn} />
                  }
                  label="Individual"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={6} sm={6}>
                <FormControlLabel
                  value="organisation"
                  control={
                    <Radio color="primary" className={classes.raidoBtn} />
                  }
                  label="Organisation"
                  fullWidth
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
        <form className={classes.form}>
          <Grid container flexDirection="row" spacing={3}>
            <Grid item sm={12} xs={12} md={6}>
              <TextField
                label={
                  typeofcontributor === "individual"
                    ? "Name"
                    : "Organisation Name"
                }
                name="name"
                value={name}
                variant="outlined"
                type="text"
                fullWidth
                onChange={this.handleChange}
              />
            </Grid>

            <Grid item sm={12} xs={12} md={6}>
              <TextField
                label="Contact Number"
                type="number"
                name="mobileno"
                value={mobileno}
                variant="outlined"
                fullWidth
                onChange={this.handleChange}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container flexDirection="row" spacing={3}>
            <Grid item sm={12} xs={12} md={6}>
              <TextField
                label="Email ID"
                name="emailid"
                value={emailid}
                variant="outlined"
                type="email"
                fullWidth
                onChange={this.handleChange}
              />
            </Grid>
            <Grid container item sm={12} xs={12} md={6} flexDirection="row">
              {/* <Grid container > */}
              <Grid item xs={5} sm={5} md={5}>
                <TextField
                  label="No of Meals"
                  name="no_of_meals"
                  value={no_of_meals}
                  variant="outlined"
                  type="number"
                  // inputProps={{
                  //   type: "number",
                  //   min: 0,
                  //   step: 1
                  // }}

                  fullWidth
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}></Grid>
              <Grid item xs={6} sm={6} md={6}>
                <TextField
                  label="Amount"
                  name="number"
                  value={`Rs.${amount}`}
                  disabled
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              {/* </Grid> */}
            </Grid>
          </Grid>

          {typeofcontributor === "individual" && amount >= 50000 && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="PAN Number"
                  name="pan"
                  value={pan}
                  variant="outlined"
                  type="text"
                  fullWidth
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>
          )}

          {typeofcontributor === "organisation" && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="GST Number"
                  name="gst"
                  value={gst}
                  variant="outlined"
                  type="text"
                  fullWidth
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>
          )}

          <Checkbox
            color="primary"
            onChange={this.handleChange}
            name="tncAccepted"
            checked={tncAccepted}
          />
          <Typography
            color="primary"
            className={classes.tncStatement}
            variant="subheading"
            onClick={this.toggleTermsAndConditions}
          >
            I accept{" "}
            <span
              style={{
                display: "inline-block",
              }}
            >
              <Typography
                variant="body2"
                style={{ color: COLORS.PRIMARY_COLOR }}
              >
                <Link href="#" onClick={this.tncHandler}>
                  Terms and Conditions*
                </Link>
              </Typography>
            </span>
          </Typography>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.placeorderBtn}
            disabled={disableButton}
            onClick={this.placeOrder}
          >
            Place Order
          </Button>
        </form>
        {loading && <MainLoading />}
      </div>
    );
  }
}

export default withStyles(styles)(Contribute);
